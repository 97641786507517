*{
  box-sizing: border-box;
  margin:0;
  padding:0;
   
}
html{
  width:100%;
 
}
.details{
  background-color: #000;
}
@font-face {
  font-family: 'Sometimes Times';
  src: local('Sometimes Times'), 
  url('/public/fonts/Sometimes-Times-X/Sometimes Times.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'BentonSans Book';
  src: local('BentonSans'), 
       url('/public/fonts/BentonSans Book/BentonSans Book.otf') format('opentype');
  font-weight: normal;
  font-style: normal;
}

#root {
  --text-color-primary: #1E1E1E;
  --text-color-secondary: #252D32;
  --main-color: #ffffff;
  --btn-color-primary: #FF7070;
  --btn-secondary-color: #D4D4D4;
  --heading-primary: 28px;
  --heading-secondary: 17px;
  --font-family-main:'Sometimes Times';
}


h1{
  font-family: 'Aspire';
  font-size: 100px;
  font-weight: 900;
  color:#fff;
 
}
h2{
  font-family: 'Amiri, serif';
  font-size: var(--heading-primary);
  text-transform: uppercase;
  letter-spacing: 0.1em;

}
h3{
  font-family: 'Amiri, serif';
  font-size: var(--heading-secondary);
  letter-spacing: 0.1em;
  text-transform: uppercase;
  color:var(--text-color-secondary)
}

h4{
  font-family:  Roboto, Oxygen, Ubuntu, 'Open Sans', 'Helvetica Neue', sans-serif;
  font-size: 18px;
}

h5{
  font-size: 16px;
}
 p{
  position: relative;
  
font-size: 16px;
line-height: 1.5;
font-family: 'BentonSans Book';
}
a{
  position: relative;
  text-decoration: none;
  
}
.label{
  display: flex;
  gap:2px
}


.process-text-1{
  margin-top: 6rem;
}
.btn-main{
  position: relative;
  top:1rem
  
}
.btn-primary{
border:1.5px solid #e0ceab;
background-color: #fff;
color:#000;
padding:10px;
border-radius:3px;
position: relative;

}

.btn-primary:hover{
   background-color: #D4D4D4;
}
.btn-secondary{
background-color: var(--btn-secondary-color);
color:#000;
padding:10px;
border-radius:3px;
}
.custom-btn{
  max-width:37.9rem;
  width: 100%;
  
}
.create-btn{
  padding: 10px;
  border-radius: 3px;
}
 .full-wdth-btn{
 background-color: #e0ceab;
  color:#000
 }

 .full-wdth-btn:hover{
  background-color: var(--btn-secondary-color);
  color:#000;
  transition: 0.5s ease;
 }
 .icon {
  width: 70px; /* Adjust the width and height as needed */
  height: 42px;
  background-color: #dad9d9; /* Background color of the circle */
  border-radius: 50%; /* Creates a circle by setting border-radius to 50% */
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  margin-right: 10px; /* Optional: Add margin if needed */
  box-shadow: 0 0 21px rgba(1, 0, 0, 0.20);
}
.custom-textarea {
  width:98%;
  max-width: 75rem;
  min-height: 10rem;
  border: 1px solid #ccc; 
  border-radius: 5px;
  resize: vertical;
  font-size: 1rem;
  margin: 1rem 0 0 0;
}


.inner-right a{
  margin-top: 2rem;
}

.logo{
  position:relative;
  top:0;
  left:0;
  display: flex;
 
}
.logo img{
  width:30%;
  
  padding: 2px 0 4px 0;

}
.logo{
  display: flex;
  align-items: center;
  justify-content: center;
  margin:0 4.5rem 0 0;
}
.footer-logo img{
  margin-left: 3rem;
}
.inner-left{
  margin-bottom: 1rem;
  
}

.left-text h2{
  margin-top: 10rem;
}
/*-----------------header----------------*/

.item{
  background-size:cover;
  background-repeat: no-repeat;
  background-position: center center;
  min-height:auto;
  
}
.mobile-carousel{
  display: none;
}



.intro .item:before{ 
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.6); /* Adjust the color and opacity as needed */
    z-index: 0;
  }

.header-content{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  width:100%;
  max-width: 50rem;
  text-align: center;
}
.header-text{
text-align: center;
 color: #ffffff;
}

/***** Video content ****/

.gallery-section{
  margin-top: 4rem;
  /* background-color: #D4D4D4; */
  padding: 0px 0 10px 0;
}

video{
  width: 100%;
  border-radius: 8px;
  padding: 10px 0 0 0;
}

@media(min-width:900px){
  .v-content {
  margin-top: 7rem;
  }

  .text-align{
    margin-top: 13rem;
  }

  .section-about .inner-left img{
    position: relative;
   width:80%;
     margin-top:1rem;
     box-shadow: 0px 0px 25px 0px rgba(0,0,0,0.1),0px 10px 15px -3px rgba(0,0,0,0.1);
     background-size:cover ;
   }

   
}

@media(max-width:812px) and (min-width:350px) {
  .footer-logo img{
    margin-left: 15rem;
  }
  .custom-btn{
    max-width:38rem;
    width: 100%;
    margin: 0 10px 0 0;
  }
  .text-align{
    margin-top: 13rem;
  }

  .about{
    margin-top: -8rem;
  }
  h2{
    font-size: 21px;
  }
  .v-content{
    padding-left: 5rem;
  }
  .left-text{
    margin:0
  }
  .text-align{
    position: relative;
    top: -10rem;
  }
  .header-content{
    max-width:19rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

  }
  .header-content h1{
    display:block;
    position: relative;
  font-size: 36px;
  }

 
  .footer-logo{
    margin-left: -8rem;
   }
 
  .item{
    background-size:contain;
    background-repeat: no-repeat;
    background-position: center center;
    min-height:auto;
    
  }


  .about-item{
    position: relative;
  
    width:70%;
    border: 1px solid red;
   
  }
  .section-about{
    display: block;
    flex-direction: column;
  }

  .section-about .inner-right{
    position: relative;
    margin-top: -5rem;
  }
  .section-products{
 
     margin-top:5rem
  }
  .section-background{
    position: relative;
    display:flex;
    flex-direction: column;
  }
  .v-content{
  margin-top: -2.5rem;
  }
  .gallery-section{
    padding: 5px 5px 20px 0;
  }

  .gallery-section video{
    position: relative;
    top:0;
    
  }

 
  .left-text{
    position: relative;
    top:-10rem;
    margin-bottom: -10rem;
   }
   .section-about .inner-left img{
    position: relative;
    width:100%
   }

   .section-about .inner-right .inner-content{
    position: relative;
    top:-7rem;
   
   }
  
   .inner-right img{
    position: relative;
    padding: 0;
    width:100%
   }
   
   .creation{
    margin-top: -4rem;
    
   }
}


@media(max-width:413px){
 
  .item-box::after{
    position: absolute;
    content:'';
    top:0;
    left:0;
    margin-bottom:-15rem;
  

  }
  .inner-right-v{
    margin-top: -8rem;
  }
  .header-text{
    display:block;
    position: relative;
    top:0;
    text-align:left;
  }
  .header-img{
    background-size:cover;
    background-repeat: no-repeat;
  }
  
  .section-about{
    display: block;
    flex-direction: column;
  }
  .section-about .inner-left h2{
   font-size: 28px;
  }
  .section-about .inner-right{
    position: relative;
    margin-top: -5rem;
  }
  .section-products{
 
    margin-top:5rem
 }
 


.video-content video{
  
  height:50rem;
}

.gallery-section{
  margin-top:1rem;
}

.process-text-3{
 margin-bottom: -15rem;
}
}
/*Intro section*/
.section-about{
  position: relative;
  top:3rem;
  left:0;
  display: flex;
  padding:10px;
}
.section-about .inner-left{
 position: relative;
 display: block;
 
}


.section-about .inner-right{
  position: relative;
  top:4rem;
  left:0;
 
}

.section-about .inner-right .inner-content{
  position: relative;
  margin-top: 10rem;

}

/* product section */

.section-products{
  position: relative;
  padding:80px 0 54px
}

.section-products .single-product{
  margin-bottom: 26px;
}

.section-products .single-product .part-1{
  position: relative;
  height:410px;
  max-height: 410px;
  margin-bottom: 20px;
  overflow: hidden;
}

.section-products .single-product .part-1::before{
  position: absolute;
  content:'';
  top:0;
  left:0;
  width:100%;
  height: 100%;
  z-index:-1
  transition all 0.3s;
}

.section-products .single-product:hover .part-1::before{
transform:scale(1.2,1.2) rotate(5deg)
}
.part-1 img{
  position: absolute;
  width: 100%;
  background-position: top;
  background-size: cover;
  background-repeat: no-repeat;
}
.section-products #product-1 .part-1::before {
 background-size: cover;
  opacity:0.5;
  transition: all 0.3s;
}

.section-products #product-2 .part-1::before {
  background-size: cover;
}

.section-products #product-3 .part-1::before {
  background-size: cover;
}

.section-products #product-4 .part-1::before {
  background-size: cover;
}

.section-products .single-product .part-1 ul {
  position: absolute;
  bottom: -41px;
  left: 20px;
  margin: 0;
  padding: 0;
  list-style: none;
  opacity: 0;
  transition: bottom 0.5s, opacity 0.5s;
}

.section-products .single-product:hover .part-1 ul {
  bottom: 30px;
  opacity: 1;
}

.section-products .single-product .part-1 ul li {
  display: inline-block;
  margin-right: 4px;
}

.section-products .single-product .part-1 ul li a{
  display: inline-block;
  width:40px;
  height:40px;
  line-height: 40px;
  background-color: #fff;
  color:#444444;
  text-align:center;
  box-shadow: 0 2px 20px rgb(50 50 50 /10%);
  transition:color 0.2
  
}

.section-products .single-product .part-1 ul li a:hover {
  color: #fe302f;
}

.section-products .single-product .part-2 .product-title {
  font-size: 1rem;
  margin:'0 auto';
}

.section-products .single-product .part-2 h4 {
  display: inline-block;

}
.section-products .single-product .part-2 .product-old-price {
  position: relative;
  padding: 0 7px;
  margin-right: 2px;
  opacity: 0.6;
}

.section-products .single-product .part-2 .product-old-price::after {
  position: absolute;
  content: "";
  top: 50%;
  left: 0;
  width: 100%;
  height: 1px;
  background-color: #444444;
  transform: translateY(-50%);
}


/*About section*/

.section{
  position: relative;
  top:3rem;
  left:0;
}

.section-background{
  position: relative;
  display:flex;
  gap: 6rem;
  
  @media (max-width: 768px) {
    gap: 0; 
    padding-left: 10px;
    margin-top: -4rem;
  }
}


.section-background .inner-left{
  padding:5px 20px 10px 0;
}
.section-background .inner-right{
 padding:0 5px 5px 0;
 
}
.section-background .inner-right img{
  position: relative;
  width:100%;
  max-width: 100%;
  height: 600px;
  max-height: 700px;
  overflow: hidden;
  margin-top:1rem;
  
}

/*video section*/
.video-content{
  position: relative;
  margin-top: 3rem;
  width:100%;
}

.video-content video{
  width:100%;
  height: auto;
}

.gallery-section{
  margin-bottom: 6rem;
  
}

/* .header-img{
  position:absolute;
  top:0;
  left:0;

  bottom:0;
  width:100%;

  
}

@media screen and(max-width:500px){
  .header{
    position:relative;
    background-color: rgb(33, 22, 6);
  }
  .header-img{
    position:relative;
    left:0;
    display: block;
    align-items: center;
    margin: 0 auto;
    background-color: rgb(129, 84, 84);
   
  }
} */

/*create  product form*/
.wrapper{
  position: relative;
  margin-top: 2rem;
}
.inner{
  width:75%;
  padding:5rem;
  margin: 0 auto;
  background-color: #D4D4D4;
  box-shadow: 1px 0px 17px -1px rgba(0,0,0,0.7);
  -webkit-box-shadow: 1px 0px 17px -1px rgba(0,0,0,0.7);
  -moz-box-shadow: 1px 0px 17px -1px rgba(0,0,0,0.7);
}

.inner h3{
  color: var(--text-color-primary);
  font-size: 58px;
}
.form-group {
  display: flex;
}
.form-group .form-wrapper {
  width: 50%;
}
.form-group .form-wrapper:first-child {
  margin-right: 40px;
}

.form-wrapper {
  margin-bottom: 27px;
}
.form-wrapper label {
  margin-bottom: 10px;
  display: block;
  text-transform: uppercase;
  color: var(--text-color-secondary);
  font-family: "Muli-Bold";
}
.form-center{

margin: 10rem auto;
}
.form-holder {
  position: relative;
}
.form-holder i {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 14px;
  font-size: 17px;
  color: #999;
}

.form-control {
  border: 1px solid #e6e6e6;
  display: block;
  width: 100%;
  height: 42px;
  padding: 0 20px 0 46px;
  color: #999;
  font-size: 15px;
}
.form-control::-webkit-input-placeholder {
  font-size: 17px;
  transform: translateY(5px);
  color: #999;
}
.form-control::-moz-placeholder {
  font-size: 17px;
  transform: translateY(5px);
  color: #999;
}
.form-control:-ms-input-placeholder {
  font-size: 17px;
  transform: translateY(5px);
  color: #999;
}
.form-control:-moz-placeholder {
  font-size: 17px;
  transform: translateY(5px);
  color: #999;
}

.error-message{
  color: #fe302f;
}


/* Sign up form span*/

.text-b{
  font-weight: 600;
}

.form-d-group{
   display: flex;
   margin-right:-20px;
}
/*product details*/


/*Expand button */

details {
  margin-bottom: 20px;
  border-bottom: 1px solid #999;
  padding-bottom: 10px;
}

details summary {
  font-weight: bold;
  font-size: 16px;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  position: relative;
}

details summary::after{
  content:'+';
  position: absolute;
  right:0;
  font-size: 25px;
  transform: rotate(0deg);
  transition: transform 0.6s ease-in;
}

details[open] summary::after{
  content:'-';
  position: absolute;
  right: 0;
  transition:0.6s ease-in;
  font-size: 25px;

}

details p {
  margin-top: 13px;
  padding: 12px;
  background-color: #fff;
  transition: all 0.2s ease-in;
}

/* ------Line animation ------*/






@keyframes loader {

	0% {
		width: 0;
	}

	100% {
		width: 100%;
	}

}


.progress-bar {
  border-radius: 10%;
	overflow: hidden;
  width: 30%;

}

span {
  display: block;
}

.progress{
  animation: loader 3s ease infinite;
  background-image: linear-gradient(90deg, #bf9b30 0%, #ffbf00 35%, #ffdc73 100%);
  width: 0;
}

.progress-bar {
max-height: 5px;
  max-width: 100%;
  position: relative;
left:15.2%;
  transform: translate3d(-50%,-50%,0);
}


/*-------------------Box style-----------------------*/

@import url('https://fonts.googleapis.com/css?family=Oswald:300,400,500,700');
@import url('https://fonts.googleapis.com/css?family=Open+Sans:300,400,600,700,800');

.gr-1 {
  background: linear-gradient(170deg, #01E4F8 0%, #1D3EDE 100%);
}

.gr-2 {
  background: linear-gradient(170deg, #B4EC51 0%, #429321 100%);
}

.gr-3 {
  background: linear-gradient(170deg, #C86DD7 0%, #3023AE 100%);
}

* {
  transition: 0.5s;
}

.h-100 {
  height: 30vh !important;
}

.align-middle {
  position: relative;
  top: 50%;
  transform: translateY(-50%);
}

.column {
  margin-top: 3rem;
  
}

.column:hover {
  padding-left: 0;
}
.card .txt h1{
  color: #000;
}
.column:hover .card .txt {
  margin-left: 1rem;
}

.column:hover .card .txt h1,
.column:hover .card .txt p {
  color: rgba(255, 255, 255, 1);
  opacity: 1;
}

.column:hover a {
  color: rgba(255, 255, 255, 1);
}

.card {
  min-height: 90px;
  margin: 0;
  padding: 1.7rem 1.2rem;
  border: none;
  border-radius: 0;
  color: rgba(0, 0, 0, 1);
  letter-spacing: 0.05rem;
  font-family: 'Oswald', sans-serif;
  box-shadow: 0 0 21px rgba(0, 0, 0, 0.27);
}

.card .txt {
  margin-left: -3rem;
  z-index: 1;
}

.card .txt h1 {
  font-size: 1.5rem;
  font-weight: 300;
  text-transform: uppercase;
}

.card .txt p {
  font-size: 0.7rem;
  font-family: 'Open Sans', sans-serif;
  letter-spacing: 0rem;
  margin-top: 33px;
  opacity: 0;
  color: rgba(255, 255, 255, 1);
}

.card a {
  z-index: 3;
  font-size: 0.7rem;
  color: rgba(0, 0, 0, 1);
  margin-left: 1rem;
  position: relative;
  bottom: -0.5rem;
  text-transform: uppercase;
}

.card a:after {
  content: "";
  display: inline-block;
  height: 0.5em;
  width: 0;
  margin-right: -100%;
  margin-left: 10px;
  border-top: 1px solid rgba(255, 255, 255, 1);
  transition: 0.5s;
}

.card .ico-card {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.card i {
  position: relative;
  right: -50%;
  top: 60%;
  font-size: 12rem;
  line-height: 0;
  opacity: 0.2;
  color: rgba(255, 255, 255, 1);
  z-index: 0;
}


/*-------------About---------------*/

.inner-right-ab{
  
  height: 600px;
  max-height: 700px;
  overflow: hidden;
  margin-top:1rem;
  border-radius: 1rem 1rem  1rem 1rem;

}
.about-content{
  text-align: center;
}
.inner-right-v{
  width:100%;
  max-width: 30rem;
  overflow: hidden;
  margin-top:2rem;
  border-radius: 1rem 1rem  1rem 1rem;
}

/**** Item Box ****/

.item-box{
  position:relative;
  top:5rem;
  box-shadow: 0 0 21px rgba(0, 0, 0, 0.1);
  height: 200px;
  margin-left:20px;

}

.item-box-content{
  padding:5px 5px 10px 10px;
  margin:1rem 0 0 0;
}

.space{
  margin-bottom: 10rem;
}











/*-------------------------item------------------*/


.process-section{
  position: relative;
  top:5rem;
}

.item{
  display: flex;
  gap:1rem;
  padding: 10px;
}
.iitem {
  display: block;
  text-align: center;
  padding: 1.5rem;
  text-decoration: none;
  box-shadow: 0 0 21px rgba(1, 0, 0, 0.20);
  transition: box-shadow 0.2s, background-color 0.2s, color 0.2s;
  margin-top: 2rem;
  color:var
}

.iitem:hover {
   color: var(--primary-color);
}
.m-hover{
  display: flex;
  gap:1rem
}
.m-hover img{
  z-index: 1;
}
.m-hover img:hover{
  position: relative;

  transform:scale(3);
  transition: transform 0.3s ease-in-out;
}



.footer-bottom{
  display:flex;
  gap:2rem;
  padding:10px;
  background-color: var(--text-color-primary);
  color:var(--main-color)
}

.footer-bottom p{
  margin-top: 0.5rem;
}
